export const tableStyle = {
  container: {
    backgroundColor: "white",
    padding: 14,
  },
  head: {
    color: "rgb(156, 164, 171)",
    fontWeight: 'bold',
    fontSize: 10,
  },
  body: {
    fontSize: 12,
    fontWeight: 'bold',
    color: "#343F5B",
  },
  tableRow: {
    "&:hover": {
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 1px 5px rgba(0,0,0,0.22)",
      cursor: "pointer",
    },
  },
}
