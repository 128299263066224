export default {
    color:{
        buttonColor : '#28a745',  //green color for button

        //used colors
        //#c9f0fd sky blue
        drawerBgColor: '#FFFFFF',
        mainColor : '#c9f0fd', //sky blue color
        mainContentBackgroundColor : '#c9f0fd',  //sky blue color
        normalButtonColor : '#20B2AA', //blue success color
        deleteButtonColor : '#D21F3C', //red color
    },
    drawerWidth : 240,
    toolbarHeight : '64px',
    toolbarHeightWithoutPX : 64,
    }